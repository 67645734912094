import React, { useState, useEffect, useRef, createRef, useCallback } from 'react';

export const useObservedItems = (observedItemIndex: number, itmes: any, setItems: React.Dispatch<any>) => {
    const itmesRefs = useRef(new Map());
    const [isObservedItemSeen, setIsObservedItemSeen] = useState(false);

    const setItemsWithRefs = useCallback(
        (items) => {
            setItems(() =>
                items?.map((item, index) => {
                    if (!item.ref || !itmesRefs?.current.has(index)) {
                        const ref = createRef();
                        itmesRefs?.current.set(index, ref);
                        return { ...item, ref };
                    }
                    return item;
                })
            );
        },
        [setItems, itmesRefs]
    );

    useEffect(() => {
        if (!itmes[observedItemIndex] || isObservedItemSeen) return;

        const targetRef = itmesRefs.current.get(observedItemIndex);
        if (!targetRef || !targetRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsObservedItemSeen(true);
                        observer.disconnect();
                    }
                });
            },
            { threshold: 0.5 }
        );

        observer.observe(targetRef.current);

        return () => observer.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itmes]);

    return { setItemsWithRefs, isObservedItemSeen };
};
