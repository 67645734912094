import React from 'react';
import classnames from 'classnames';
import { Section } from '@fiverr-private/fit';
import { ROLLOUTS } from '@fiverr-private/listing_experiments';
import { BriefFloatingFormTrigger, ListingFlow } from '@fiverr-private/dm_brief_creation';
import { useInBriefFloatingFormExperiment } from '../../../../hooks/useInBriefFloatingFormExperiment';
import { LISTINGS_PAGE, MAX_ITEMS_TO_SHOW_NO_RESULTS } from '../../../../constants';
import { useAppContext } from '../../../context/listingsContext';
import AssetsVerifier from '../../AssetsVerifier';
import { ProOrientedSearchModal } from '../../ProOrientedSearchModal';
import Header from '../Header';
import { useBusinessListingsContext } from '../Context';
import { useBffForceOpen } from './useBffForceOpen';

import styles from './index.module.scss';

const BusinessLayout = ({ isObservedItemSeen, children }) => {
    const {
        flow,
        categoryIds,
        query,
        listingQuery,
        significantLeafCategories,
        dominateSubCategoryId,
        currency,
        appData,
        appFilters,
    } = useAppContext();
    const inBffExperiment = useInBriefFloatingFormExperiment();

    const { rollouts, resultsCount, isExpertListings } = useBusinessListingsContext();
    const inMustangsVerifyAssetsRollout = rollouts[ROLLOUTS.MUSTANGS_VERIFY_ASSETS];

    const hasFewResults = isExpertListings && resultsCount < MAX_ITEMS_TO_SHOW_NO_RESULTS;
    const shouldShowBff =
        inBffExperiment && !hasFewResults && resultsCount > 0 && Object.values(ListingFlow).includes(flow);
    const firstPages = !(appData?.pagenation?.page > 2);
    const notFiltered = !(appFilters?.selectedFilters?.length > 0);
    const shouldAllowForcedOpen = shouldShowBff && firstPages && notFiltered;

    const leafCategory = categoryIds.nestedSubCategoryId || categoryIds.subCategoryId;
    const dlc = significantLeafCategories?.[0] || dominateSubCategoryId;

    const { forceOpenSettings, resetForceOpenSettings } = useBffForceOpen(shouldAllowForcedOpen, isObservedItemSeen);

    return (
        <Section className={classnames(styles.main, 'listings-perseus')}>
            <Header />
            <div className="flex flex-items-start">{children}</div>
            {inMustangsVerifyAssetsRollout && <AssetsVerifier />}
            <ProOrientedSearchModal />
            {shouldShowBff && (
                <BriefFloatingFormTrigger
                    listingFlow={flow}
                    leafCategory={Number(leafCategory)}
                    searchQuery={query || listingQuery}
                    dlc={Number(dlc)}
                    source={LISTINGS_PAGE}
                    currency={currency}
                    forceOpen={forceOpenSettings.isForceOpen}
                    afterForceOpen={forceOpenSettings.afterForceOpen}
                    triggerScenario={forceOpenSettings.triggerScenario}
                    onClose={resetForceOpenSettings}
                    afterForceOpenRefused={resetForceOpenSettings}
                />
            )}
        </Section>
    );
};

export default BusinessLayout;
