import React, { Fragment } from 'react';
import classnames from 'classnames';
import { isEmpty } from 'lodash';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import { Container, Stack } from '@fiverr-private/layout_components';
import { Text } from '@fiverr-private/typography';
import { useIsClient } from '@fiverr-private/hooks';
import { CARD_VARIANT } from '../../shared/constants';
import expertCardListingsPropTypes from '../../entries/ExpertCardListings/types';
import ExpertCard from '../ExpertCard';
import { onExpertCardImpression } from '../ExpertCard/impression';

import '../../entries/ExpertCardListings/index.scss';

const ExpertCardListingsContent = ({
    items = [],
    currency = {},
    containerImpressionEnrichment = {},
    onImpression,
    source,
    cardVariant = CARD_VARIANT.DEFAULT,
    forceSmallCard = false,
    displayOptions = {},
    renderFooterContent,
    banner,
    saveToListProps = {},
    notes,
    inProCatalogGridExp,
    showHourlyPrice = false,
    hourlyRateRolloutActive = false,
    pageName,
}) => {
    const [ref, containerId] = useImpressionContainer();
    useImpressionItem({
        selfRef: ref,
        selfId: containerId,
        sendImpressionToBQ: false,
        intersectionThreshold: 0,
        enrichment: containerImpressionEnrichment,
        onImpression: onImpression ?? ((...args) => onExpertCardImpression(pageName, 'expert_card_listings', ...args)),
    });

    const isClient = useIsClient();
    const { position: bannerPosition, Component: BannerComponent } = banner || {};

    // eslint-disable-next-line react/prop-types
    const BannerWrapper = ({ bannerPosition }) => (
        <div style={{ gridArea: `${bannerPosition + 1} / 1 / span 1 / -1` }}>
            <BannerComponent />
        </div>
    );

    return (
        <div className={classnames('listings-expert-cards')} ref={ref}>
            {items.map((item, index) => (
                <Fragment key={item.seller.username}>
                    {BannerComponent && bannerPosition === index && <BannerWrapper bannerPosition={bannerPosition} />}
                    <Stack gap="2" display={isEmpty(notes) ? 'contents' : 'flex'} direction="column">
                        <div ref={item.ref}>
                            <ExpertCard
                                key={item.seller.username}
                                {...item}
                                displayOptions={displayOptions}
                                position={index + 1}
                                cardVariant={cardVariant}
                                forceSmallCard={forceSmallCard}
                                currency={currency}
                                source={source}
                                onImpression={onImpression}
                                lazyLoadAssets={!isClient}
                                renderFooterContent={renderFooterContent}
                                saveToListProps={saveToListProps}
                                inProCatalogGridExp={inProCatalogGridExp}
                                showHourlyPrice={showHourlyPrice}
                                hourlyRateRolloutActive={hourlyRateRolloutActive}
                                pageName={pageName}
                            />
                        </div>
                        {notes?.[item.seller.username] && (
                            <Container backgroundColor="grey_200" borderRadius="lg">
                                <Text className="note" padding="4">
                                    {notes[item.seller.username]}
                                </Text>
                            </Container>
                        )}
                    </Stack>
                </Fragment>
            ))}
        </div>
    );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { layout, isLoading, skeletonAmount, ...propTypes } = expertCardListingsPropTypes;
ExpertCardListingsContent.propTypes = propTypes;

export default ExpertCardListingsContent;
