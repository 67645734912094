import { arrayOf, bool, func, node, number, object, oneOf, shape, string, any } from 'prop-types';
import { gig as gigType } from '../GigCarousel/Carousel/types';
import { highlightsDataType, highlightsType } from '../SellerHighlight/types';
import { cardVariantType, sellerType } from '../../shared/types';
import { CTAS } from '../../shared/constants';

const expertise = object;

export const ratingType = shape({
    averageValuation: number,
    totalReviewsCount: number,
});

export const biEnrichmentType = shape({
    pageName: string,
    componentName: string,
    params: object,
    seller: sellerType,
});

export const clickableAreaPropsTypes = {
    children: node,
    setIsHovered: func,
    clickHandlerArgs: object,
};

export const displayOptionsType = shape({
    showPrice: bool,
    showSaveExpert: bool,
    showContact: bool,
    showSeeProfile: bool,
    showHeaderSaveExpert: bool,
    showHeaderContact: bool,
    showHeaderSeeProfile: bool,
    showJobTitle: bool,
    /**
     * Which button should be positioned and styled in the footer as the main CTA
     */
    mainCta: oneOf(Object.values(CTAS)),
});

const enrichedDetailsType = shape({
    /**
     * Client's name
     */
    name: string,
    /**
     * The url of the logo
     */
    logo: string,
    /**
     * The industry of the client
     */
    industry: string,
    /**
     * In fortune indicator
     */
    inFortune: bool,
    /**
     * The image file name
     */
    imageName: string,
});

export const notableClientType = shape({
    /**
     * Details of a specific notable client
     */
    enrichedDetails: enrichedDetailsType, // TODO:
});

export default {
    /**
     * Seller's details
     */
    seller: sellerType,
    /**
     * The rating of the seller
     */
    rating: shape(ratingType),
    /**
     * Identifier of the highlight that should be displayed
     */
    highlight: string,
    /**
     * Number of seller's completed orders
     */
    completedOrders: number,
    /**
     * Seller's notable clients
     */
    notableClients: arrayOf(notableClientType),
    /**
     * Seller's available expertises
     */
    expertises: arrayOf(expertise),
    /**
     * Seller's available gigs
     */
    gigs: arrayOf(gigType),
    /**
     * An object with all the possible highlights, the selected highlight value is true, the rest are false
     */
    highlights: highlightsType,
    /**
     * An object with all the extra data that is needed for the selected highlight
     */
    highlightsData: highlightsDataType,
    /**
     * Callback to run when impression occurs
     */
    onImpression: func,
    /**
     * The position of the card (index) inside the listings container
     */
    position: number,
    /**
     * Variant of the card - currently we have one variant that indicates if visuals gallery should be shown
     */
    cardVariant: cardVariantType,
    /**
     * The source param in the url
     */
    source: string,
    /**
     * The context_referrer param in the url
     */
    contextReferrer: string,
    /**
     * Predefined impression id for the card
     */
    impressionId: string,
    /**
     * Show/hide price, and on small footer - save expert, contact and see profile buttons
     */
    displayOptions: displayOptionsType,
    /**
     * The class name for the card container
     */
    cardContainerClassName: string,
    /**
     * Currency as a fallback to the listings context
     */
    currency: object,
    /**
     * Component name for BI
     */
    componentName: string,
    /**
     * disable/enable swipe mode in touch screens
     */
    disableSwipeMode: bool,
    /**
     * Lazy load most of the card assets, to be used in pages that show many cards to avoid loading all assets at page load
     */
    lazyLoadAssets: bool,
    /**
     * describe if the card type is Agency
     */
    isAgencyCard: bool,
    /**
     * Render function to render custom footer content
     */
    renderFooterContent: func,
    /**
     * Props override for the save to list component
     */
    saveToListProps: object,
    /**
     * Optional callback to mutate the freelancer navigation url
     */
    mutateUrl: func,
    /**
     * Render custom buttons in the header
     */
    headerCustomButtons: arrayOf(
        shape({
            label: string,
            onClick: func,
            isCta: bool,
            elementName: string,
            loading: bool,
            disabled: bool,
            variant: string,
            icon: node,
        })
    ),
    /**
     * Render custom buttons in the footer
     */
    footerCustomButtons: arrayOf(
        shape({
            label: string,
            onClick: func,
            isCta: bool,
            elementName: string,
            loading: bool,
            disabled: bool,
            variant: string,
            icon: node,
        })
    ),
    /**
     * Whether the card is in the pro catalog grid experiment
     */
    inProCatalogGridExp: bool,
    /**
     * hourly rate props
     */
    showHourlyPrice: bool,
    hourlyRateRolloutActive: bool,
    /**
     * Override the default card click event with a custom handler
     */
    onClick: func,
    onSellerDetailsClick: func,
    pageName: string,
    ref: any,
};
