import React, { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import { logger } from '@fiverr-private/perseus';
import { I18n } from '@fiverr-private/i18n-react';
import { useImpressionContainer, useImpressionItem } from '@fiverr-private/impressionable';
import GigCardListings from '@fiverr-private/gig_listings/src/entries/GigCardListings';
import { FiverrChoiceBadge } from '@fiverr-private/badges';
import { Container } from '@fiverr-private/layout_components';
import { ListingCarousel } from '@fiverr-private/listing_carousels';
import { tracker } from '@fiverr-private/listing_lib';
import { AppContext } from '../../../store';
import ErrorBoundary from '../errors/error_boundary/ErrorBoundary';
import { ListingsImpressionContext } from '../../../../../shared/context/ListingsImpressionContainer/ListingsImpressionContainer';
import { useInResponsiveCarouselsExperiment } from '../../../hooks/useInResponsiveCarouselsExperiment/useInResponsiveCarouselsExperiment';
import ModalityCardWrapper from './ModalityCardWrapper';
import { ModalitiesHeaderContent } from './ModalitiesHeaderContent';
import { useGigModalitiesConfig } from './useGigModalitiesConfig/useGigModalitiesConfig';

import './styles.scss';

const Modalities = ({ isNarrowView = false }) => {
    const { showMediumBucketsModalities, rollouts, isLoadingListings } = useContext(AppContext);
    const { onImpression } = useContext(ListingsImpressionContext);
    const { gigs, gigToBucket, type, sourceComponent, modalitiesListings, priceConverter } = useGigModalitiesConfig();
    const shouldShowNewCarousel = useInResponsiveCarouselsExperiment() || isNarrowView;

    const [containerRef, containerId] = useImpressionContainer();
    useImpressionItem({
        selfId: containerId,
        selfRef: containerRef,
        sendImpressionToBQ: false,
        enrichment: {
            ...(sourceComponent && { name: sourceComponent }),
            modalities_carousel: {},
        },
        intersectionThreshold: 0.01,
        onImpression,
    });

    useEffect(() => {
        try {
            tracker.viewChoiceModalities();
        } catch (e) {
            logger.warn('Failed to report Fiverr Choice Modalities to MixPanel', e);
        }
    }, []);

    const CardWrapper = useCallback(
        (props) => (
            <ModalityCardWrapper gigToBucketMap={gigToBucket} type={type} {...props} converter={priceConverter} />
        ),
        [gigToBucket, priceConverter, type]
    );

    if (shouldShowNewCarousel) {
        return (
            <ListingCarousel
                listings={modalitiesListings}
                gigs={gigs}
                isLoading={isLoadingListings}
                backgroundColor="grey_200"
                HeaderContent={ModalitiesHeaderContent}
                Wrapper={CardWrapper}
                onImpression={onImpression}
                trackView={() => tracker.viewChoiceModalities}
                sourceComponent={sourceComponent}
                rollouts={rollouts}
            />
        );
    }

    const HeaderComponent = () => (
        <header className="modalities-header">
            <Container marginRight="4" alignSelf="center" className="modalities-popup">
                <FiverrChoiceBadge />
            </Container>
            <h3
                className={classNames({
                    'md-modalities-title tbody-2': showMediumBucketsModalities,
                })}
            >
                <I18n k="search_perseus.choice_modalities.title" />
            </h3>
        </header>
    );

    const gridContainerSize = isNarrowView ? 'sm' : 'md';

    return (
        <ErrorBoundary>
            <div
                className={classNames('modalities', { 'md-modalities': showMediumBucketsModalities })}
                ref={containerRef}
            >
                <GigCardListings
                    {...modalitiesListings}
                    HeaderComponent={HeaderComponent}
                    gigs={gigs}
                    listingsContainerClassName={classNames({ 'modalities-grid-view': showMediumBucketsModalities })}
                    Wrapper={CardWrapper}
                    onImpression={onImpression}
                    isExperiential={true}
                    rollouts={rollouts}
                    isLoading={isLoadingListings}
                    gridContainerSize={gridContainerSize}
                />
            </div>
        </ErrorBoundary>
    );
};

export default Modalities;
