import { noop } from 'lodash';
import { Gig } from '@fiverr-private/listing_types';
import { fetchChatDataApi } from '../assistantContainer/fetchChatDataApi';
import { ListingChatContext } from '../../../../../lib/types/ListingChatContext';

const parseRecommendations = (recommendations: Record<string, Gig[]> = {}) => {
    if (!recommendations) {
        return [];
    }

    return Object.keys(recommendations)
        .reduce((prev, recommendationKey) => [...prev, ...recommendations[recommendationKey]], [] as Gig[])
        .map((gig) => ({ gigId: gig.gigId }));
};

const parseModalitiesGigs = (modalitiesGigs: Gig[]) => modalitiesGigs.map((gig) => ({ gigId: gig.gigId }));

export const prepareAssistantConversationProps = ({
    rollouts,
    currentUser,
    assistantData,
    onReceiveChat,
    onMessageSend,
    onReceiveMessage,
    onReceiveListingAttributes,
    listingAttributes,
    gigs,
    dominantLeafCategory,
    significantLeafCategories,
    setIsAssistantOpen,
    recommendations,
    modalitiesGigs,
}) => {
    const { streamId, chatId, modifiedQueryString } = assistantData;
    const mappedRecommendations = parseRecommendations(recommendations);
    const mappedModalitiesGigs = parseModalitiesGigs(modalitiesGigs);
    const mappedGigs = gigs.map((gig) => ({ gigId: gig.gigId }));
    const chatContext: ListingChatContext = {
        listingAttributes,
        gigs: [...mappedGigs, ...mappedRecommendations, ...mappedModalitiesGigs],
        ...(dominantLeafCategory && { dlc: Number(dominantLeafCategory) }),
        slc: significantLeafCategories?.map(Number),
        modifiedQueryString,
    };

    const onCollapseButtonClick = () => {
        setIsAssistantOpen(false);
    };

    return {
        pageName: 'search',
        statsPrefix: 'listing_assistant',
        currentUser,
        chatId,
        chat: {
            id: chatId,
            realtimeChannelName: streamId,
        },
        rollouts,
        updateChat: onReceiveChat,
        onChatClose: noop,
        onChatLoad: onReceiveChat,
        onChatRename: noop,
        onMessageSend: () => {
            onMessageSend();
        },
        messageFormatter: {
            enrichRealtimeMessage: ({ rawMessage, chatId }) => ({ chatId, ...rawMessage.payload }),
            fetchChatData: fetchChatDataApi,
            retryMessageFormatter: noop,
        },
        messagePath: 'go_converse_post_listings',
        onReceiveMessage,
        channelsMap: {
            assistant_search_event: onReceiveListingAttributes,
        },
        chatContext,
        isLeanHeader: true,
        shouldShowCollapseButton: true,
        onCollapseButtonClick,
        allowEmptyMessages: true,
        unsubscribeOnUnmount: false,
    };
};
