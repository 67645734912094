export const EXPERIMENTS = {
    // Keep Sorted by Experiment ID

    // TODO LOCK & Cleanup OPEN Experiment
    ZEBRAS_ACQ_BACK_TO_FIVERR_POPUP: 3546,

    // TODO Cleanup Closed Experiment
    LIONS_MT_VS_HT: 3775,
    CAT_MW_SC_BANNER: 3904,
    GIG_IMAGE_RATIO_CONTAIN: 4232,
    CAT_I_WILL_PREFIX_REMOVAL: 4227,
    CAT_ONLINE_PRO_TOGGLE_REMOVAL: 4256,
    CAT_BOOK_CONSULTATION: 4296,
    HAWKS_SORT_BY_PRICE_POST_FREEZE: 4352,

    // Relevant Experiments
    CARIBOU_RATING_SCORE_UPDATES: 4268,
    HAWKS_LISTINGS_SIDE_FILTERS: 4405,
    HAWKS_RESPONSIVE_LISTINGS_CAROUSELS: 4437,
    HAWKS_OVERLAY_BADGES: 4462,
    OWLS_MOBILE_WEB_CAROUSEL: 4464,
    PROMOTED_VIDEO_STRIP: 4368,
    MUSTANGS_PRO_CATALOG_GRID: 4482,
    HAWKS_LISTINGS_SELLER_LANGUAGE_SIDE_FILTERS: 4508,
    AGENCIES_IN_LISTINGS_GD: 4558,
    ADS_PLACEMENT: 4711,
    DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_GUEST: 4605,
    DOLPHINS_HOURLY_RATE_BANNER_LISTINGS_USER: 4606,
    PROFESSION_BASED_CATALOG_LISTINGS_3: 4585,
    PROFESSION_BASED_CATALOG_LISTINGS_4: 4607,
    ZEBRAS_HOURLY_RATE: 4652,
    OWLS_PROFESSION_BASED_CATALOG_LISTINGS_UPDATE_ROLE_CARD: 4657,
    ZEBRAS_MP_HOURLY_FILTER: 4736,
    DOLPHINS_MP_LISTINGS_PJM_BANNER_DESKTOP: 4684,
    MUSTANGS_BFF_IN_PRO_LISTINGS: 4737,
    HAWKS_PRICE_UPFRONT_V3: 4760,
};
