import { compact, find, isEmpty, isNil, toNumber } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { logger } from '@fiverr-private/perseus';
import { env } from '@fiverr-private/futile';
import { FILTER, LASTLY_APPLIED_FILTERS_KEY } from '@fiverr-private/listing_lib';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';
import { BI_SOURCES, FLOWS, SORT_BY_FILTER_TYPES, VIEW_TYPE } from '../../../../utils/constants';
import { DISPLAY_TYPES, SORT_GROUPING, TOP_GROUPING } from '../../../../config/filters';
import { formatPriceBuckets, formatPricingFactor } from '../../../../utils';
import { decideOnSellerToggleFilters } from '../../seller_toggle_filter';
import { hasSupply } from '../../floating_topbar/menus/content/toggleFilters/utils';
import { getFilterById } from '../../util';
import { CONTEXT_ENUM } from '../../../../../../shared/context/ListingsImpressionContainer/constants/contextEnum';

/** @deprecated Not used in New Roles Page */
export const getListingsEnrichment = ({
    listings,
    appFilters,
    activeFilters,
    activeExperiments,
    searchComponentData = {},
    assistantData,
    isCurrentListingCreatedByAssistant,
    currentListingIndex,
}) => {
    const { pageCtxId: originalPageCtxId } = getContext();
    if (!listings) {
        logger.warn('getListingsEnrichment - received empty listings prop');
        return {};
    }

    const { impressionEnrichment = {}, context: listingsContext = {} } = listings;
    const { listings: listingsEnrichment = {}, gigData: gigEnrichment = {} } = impressionEnrichment;
    const { nonTranslatedQuery, modifiedQueryString, querySourceLanguage } = searchComponentData;

    if (isEmpty(listingsContext)) {
        logger.warn('getListingsEnrichment - received empty listingsContext');
    }

    if (isEmpty(listingsEnrichment)) {
        logger.warn('getListingsEnrichment - received empty listingsEnrichment');
    }

    const context = getPageContext(listingsContext.flowName);
    const filters = getFilters(appFilters, activeFilters);

    const result = {
        listing: {
            listing_attributes: {
                results_number: listingsEnrichment.number_of_results,
                page_num: listingsContext.page,
                sorter: getSortType(listingsEnrichment.filter_tab),
                context,
                fiverr_context: getFiverrContext(listingsContext.flowName),
                view_type: getViewType(listingsEnrichment.view_type),
                toggles_list: getTogglesList(appFilters?.filters),
                active_experiments: getActiveExperiments(activeExperiments),
                listing_id: `${originalPageCtxId}_${currentListingIndex.current}`,
                created_by_neo: isCurrentListingCreatedByAssistant.current,
                ...(assistantData && {
                    chat_id: assistantData.chatId,
                    ...(assistantData.shouldShowAssistant && {
                        neo_default_state: assistantData.enable ? 'NEO_DEFAULT_STATE_OPEN' : 'NEO_DEFAULT_STATE_CLOSED',
                    }),
                }),
                ...filters,
            },
        },
    };

    const {
        listing: { listing_attributes },
    } = result;

    if (context === CONTEXT_ENUM.SUBCATEGORY) {
        listing_attributes.category_attributes = {
            category_id: listingsEnrichment.category_id,
            subcategory_id: listingsEnrichment.sub_category_id,
            nested_subcategory_id: listingsEnrichment.nested_sub_category_id,
            leaf_category_id: listingsEnrichment.nested_sub_category_id || listingsEnrichment.sub_category_id,
        };
    } else if (context === CONTEXT_ENUM.SEARCH) {
        listing_attributes.search_attributes = {
            dominant_leaf_category: gigEnrichment.dominant_leaf_category,
            significant_leaf_categories: gigEnrichment.significant_leaf_categories?.split(','),
            search_query: nonTranslatedQuery ? nonTranslatedQuery : listingsEnrichment.search_query,
            ...(modifiedQueryString && { modified_search_query: modifiedQueryString }),
            ...(nonTranslatedQuery && { translated_search_query: listingsEnrichment.search_query }),
            ...(querySourceLanguage && { query_source_language: querySourceLanguage }),
        };

        const {
            autocomplete_original_term,
            autocomplete_type,
            autocomplete_position,
            autocomplete_sub_type,
            autocomplete_leaf_category_id,
        } = listingsEnrichment;

        if (autocomplete_type) {
            listing_attributes.search_attributes.autocomplete = {
                original_term: autocomplete_original_term,
                type: autocomplete_type,
                position: autocomplete_position,
                sub_type: autocomplete_sub_type,
                leaf_category_id: autocomplete_leaf_category_id,
            };
        }

        if (listingsContext.pos) {
            listing_attributes.search_attributes.related = {
                position: toNumber(listingsContext.pos),
            };
        }
    }

    return result;
};

export const getPageContext = (flowName) => {
    switch (flowName) {
        case FLOWS.CATEGORY:
        case FLOWS.BUSINESS_CATEGORIES: {
            return CONTEXT_ENUM.SUBCATEGORY;
        }

        case FLOWS.SEARCH:
        case FLOWS.BUSINESS_SEARCH: {
            return CONTEXT_ENUM.SEARCH;
        }

        default: {
            return CONTEXT_ENUM.UNSPECIFIED;
        }
    }
};

export const getSortType = (filterTab) => {
    switch (filterTab) {
        case SORT_BY_FILTER_TYPES.AUTO: {
            return 'SORTER_RECOMMENDED';
        }

        case SORT_BY_FILTER_TYPES.NEW: {
            return 'SORTER_NEW';
        }

        case SORT_BY_FILTER_TYPES.RATING: {
            return 'SORTER_BEST_SELLING';
        }

        case SORT_BY_FILTER_TYPES.PRICE_ASC: {
            return 'SORTER_PRICE_ASCENDING';
        }

        case SORT_BY_FILTER_TYPES.PRICE_DESC: {
            return 'SORTER_PRICE_DESCENDING';
        }

        default: {
            return 'SORTER_UNSPECIFIED';
        }
    }
};

export const getFiverrContext = (flowName) => {
    if (flowName === FLOWS.BUSINESS_CATEGORIES || flowName === FLOWS.BUSINESS_SEARCH) {
        return 'FIVERR_CONTEXT_BUSINESS';
    }

    return 'FIVERR_CONTEXT_DEFAULT';
};

export const getTogglesList = (filters = []) => {
    if (!filters) {
        return [];
    }

    const displayedToggles = [];
    const { experience: { isBusiness } = {} } = getContext();

    const proToggle = getFilterById(filters, FILTER.PRO.ID);
    if (hasSupply(proToggle?.options)) {
        displayedToggles.push('TOGGLE_PRO');
    }

    const aiDeliveryToggle = getFilterById(filters, FILTER.AI_DELIVERY.ID);
    if (!isEmpty(aiDeliveryToggle?.options)) {
        displayedToggles.push('TOGGLE_AI_DELIVERY');
    }

    const onlineToggle = getFilterById(filters, FILTER.IS_SELLER_ONLINE.ID);
    if (hasSupply(onlineToggle?.options)) {
        displayedToggles.push('TOGGLE_IS_ONLINE');
    }

    const localToggle = decideOnSellerToggleFilters(filters);
    if (!isBusiness && !!localToggle && hasSupply(localToggle?.options)) {
        displayedToggles.push('TOGGLE_LOCAL_SELLERS');
    }

    return displayedToggles;
};

export const getFilters = (appFilters = {}, activeFilters = {}, defaultFilters = {}) => {
    let activeFiltersWithoutDefaults = {};

    const defaultFiltersEmpty = isEmpty(defaultFilters);

    if (defaultFiltersEmpty) {
        activeFiltersWithoutDefaults = activeFilters;
    } else {
        for (const filterName in activeFilters) {
            if (defaultFilters[filterName]) {
                const filterValues = activeFilters[filterName];
                const nonDefaultValuesForFilter = filterValues.filter(
                    (filterValue) => !defaultFilters[filterName].includes(filterValue)
                );

                if (!isEmpty(nonDefaultValuesForFilter)) {
                    activeFiltersWithoutDefaults[filterName] = nonDefaultValuesForFilter;
                }
            } else {
                activeFiltersWithoutDefaults[filterName] = activeFilters[filterName];
            }
        }
    }

    const activeFiltersForEvent = getActiveFilters(appFilters, activeFiltersWithoutDefaults);
    const defaultFiltersForEvent = defaultFiltersEmpty
        ? getStaticDefaultFilters(appFilters)
        : parseFiltersForEvent(defaultFilters);

    return {
        active_filters: activeFiltersForEvent,
        lastly_applied_filters: getLastlyAppliedFilters(appFilters),
        default_filters: defaultFiltersForEvent,
    };
};

const formatCustomFilters = (appFilters, activeFilters) => {
    const filters = appFilters.filters || [];

    const { options: priceBucketOptions } = filters.find(({ id }) => id === FILTER.PRICE_BUCKETS.ID) || {};
    const formattedPriceBuckets = formatPriceBuckets(activeFilters, priceBucketOptions);

    const { selectedOption: selectedPricingFactor } =
        (activeFilters[FILTER.PRICING_FACTOR.ID] && filters.find(({ id }) => id === FILTER.PRICING_FACTOR.ID)) || {};
    const formattedPricingFactor = formatPricingFactor(selectedPricingFactor);

    return {
        ...formattedPriceBuckets,
        ...formattedPricingFactor,
    };
};

const getStaticDefaultFilters = (appFilters) => {
    const res = [];

    const pricingFactorFilter = appFilters.filters?.find(({ id }) => id === FILTER.PRICING_FACTOR.ID);

    if (pricingFactorFilter?.defaultOption) {
        res.push({ name: FILTER.PRICING_FACTOR.ID, values: [pricingFactorFilter.defaultOption.value] });
    }

    return res;
};

const getActiveFilters = (appFilters, activeFilters) => {
    const formattedCustomFilters = formatCustomFilters(appFilters, activeFilters);
    const modifiedActiveFilters = { ...activeFilters, ...formattedCustomFilters };

    return parseFiltersForEvent(modifiedActiveFilters);
};

const parseFiltersForEvent = (filters) =>
    Object.keys(filters).map((filterName) => {
        let values = filters[filterName];

        if (filterName === FILTER.GIG_PRICE_RANGE.ID) {
            values = values.toString();
        }

        if (!Array.isArray(values)) {
            values = [values];
        }

        values = values.filter((value) => !isNil(value));

        return {
            name: filterName,
            values,
        };
    });

const getLastlyAppliedFilters = (appFilters) => {
    if (!env.browser) {
        return [];
    }

    const storedLastlyAppliedFilters = localStorage.get(LASTLY_APPLIED_FILTERS_KEY);

    if (!storedLastlyAppliedFilters) {
        return [];
    }

    const lastlyAppliedFilters = JSON.parse(storedLastlyAppliedFilters) || [];
    localStorage.remove(LASTLY_APPLIED_FILTERS_KEY);

    const { subCategory = {} } = appFilters;
    const filters = appFilters.filters || [];

    const result = lastlyAppliedFilters.map((lastlyAppliedFilter) => {
        const { name, indexInGroup, filtersGroupName, source, isAboveTheFold } = lastlyAppliedFilter;
        let { values } = lastlyAppliedFilter;

        if (isNil(values) || (Array.isArray(values) && !values.length)) {
            return null;
        }

        if (name === FILTER.GIG_PRICE_RANGE.ID) {
            values = values.toString();
        }

        if (!Array.isArray(values)) {
            values = [values];
        }

        let filter;

        if (filtersGroupName === TOP_GROUPING.CATEGORY) {
            filter = subCategory;
        } else {
            filter = find(filters, { id: name });
        }

        if (!filter) {
            return null;
        }

        return {
            name,
            user_input_type: getFilterInputType(filter.display_type),
            source_type: getSourceType(source),
            dropdown_filters_group_name: getDropdownFiltersGroupName(filtersGroupName),
            dropdown_position_in_filters_group: indexInGroup + 1,
            is_above_fold: isAboveTheFold,
            values: values.map((value, valueIndex) => {
                const { options, children } = filter;
                let items = options || children;

                if (!Array.isArray(items)) {
                    const { fromOptions, toOptions } = items;

                    if (valueIndex === 0 && fromOptions) {
                        items = fromOptions;
                    } else if (valueIndex === 1 && toOptions) {
                        items = toOptions;
                    } else {
                        items = [];
                    }
                }

                const index = items.length === 1 ? valueIndex : items.findIndex((item) => item.id === value);

                return {
                    position_in_filter: index + 1,
                    value,
                };
            }),
        };
    });

    return compact(result);
};

const getFilterInputType = (selector) => {
    switch (selector) {
        case DISPLAY_TYPES.TOGGLE:
        case DISPLAY_TYPES.CHECKBOX:
        case DISPLAY_TYPES.TOGGLE_DETAILED:
        case DISPLAY_TYPES.PRO: {
            return 'USER_INPUT_TYPE_TOGGLE';
        }

        case DISPLAY_TYPES.INPUT_RANGE:
        case DISPLAY_TYPES.NUMBER_INPUT: {
            return 'USER_INPUT_TYPE_TEXT';
        }

        case DISPLAY_TYPES.AUTO_COMPLETE:
        case DISPLAY_TYPES.CHECKBOX_GROUP: {
            return 'USER_INPUT_TYPE_CHECKBOX';
        }

        case DISPLAY_TYPES.RADIO: {
            return 'USER_INPUT_TYPE_LIST';
        }

        default: {
            return 'USER_INPUT_TYPE_UNSPECIFIED';
        }
    }
};

const getSourceType = (source) => {
    switch (source) {
        case BI_SOURCES.DROPDOWN_FILTERS: {
            return 'SOURCE_TYPE_DROPDOWN_FILTERS';
        }

        case BI_SOURCES.TOGGLE_FILTERS: {
            return 'SOURCE_TYPE_TOGGLES';
        }

        case BI_SOURCES.VISUAL_FILTERS:
        case BI_SOURCES.PRICE_BUCKETS: {
            return 'SOURCE_TYPE_VISUAL_FILTERS';
        }

        case BI_SOURCES.SSYL_SEE_MORE_BUTTON: {
            return 'SOURCE_TYPE_SELLER_SPEAKS_YOUR_LANGUAGE_SEE_MORE_BUTTON';
        }

        case BI_SOURCES.DRAWER: {
            return 'SOURCE_TYPE_DRAWER';
        }

        default: {
            return 'SOURCE_TYPE_UNSPECIFIED';
        }
    }
};

export const getDropdownFiltersGroupName = (filtersGroupName) => {
    switch (filtersGroupName) {
        case TOP_GROUPING.CATEGORY: {
            return 'DROPDOWN_GROUP_NAME_CATEGORY';
        }

        case TOP_GROUPING.SERVICE: {
            return 'DROPDOWN_GROUP_NAME_SERVICE_OPTIONS';
        }

        case TOP_GROUPING.SELLER: {
            return 'DROPDOWN_GROUP_NAME_SELLER_DETAILS';
        }

        case TOP_GROUPING.DELIVERY_TIME: {
            return 'DROPDOWN_GROUP_NAME_DELIVERY_TIME';
        }

        case TOP_GROUPING.BUDGET: {
            return 'DROPDOWN_GROUP_NAME_BUDGET';
        }

        case TOP_GROUPING.TOGGLERS: {
            return 'DROPDOWN_GROUP_NAME_TOGGLES';
        }

        case TOP_GROUPING.VISUAL_FILTERS: {
            return 'DROPDOWN_GROUP_NAME_VISUAL_FILTERS';
        }

        case SORT_GROUPING: {
            return 'DROPDOWN_GROUP_NAME_SORT_BY';
        }

        default: {
            return 'DROPDOWN_GROUP_NAME_UNSPECIFIED';
        }
    }
};

export const getViewType = (viewType) => (viewType === VIEW_TYPE.GRID ? 'VIEW_TYPE_GRID' : 'VIEW_TYPE_LIST');

export const getActiveExperiments = (activeExperiments = {}) =>
    Object.entries(activeExperiments).map(([id, group]) => ({ id: toNumber(id), group: toNumber(group) }));
