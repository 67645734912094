import React, { useEffect } from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { RealtimeListener } from '@fiverr-private/assistant_realtime';
import { tracker } from '@fiverr-private/listing_lib';
import { trackBrazeEvent, trackSearchPerseusPageLoad } from '../utils/gtmTracking/gtmTracking';
import { ListingsPerseusPropTypes } from '../types';
import { FLOWS } from '../utils/constants';
import Store from '../store';
import { ErrorPage, AppError, ErrorBoundary } from './common';
import SubCategories from './subcategories/SubCategories';
import Search from './search/Search';
import { BusinessGigsListing } from './business_listings/BusinessGigsListing';
import './index.scss';
import './common/listings/index.scss';

const ListingsComponentMapper = {
    [FLOWS.CATEGORY]: SubCategories,
    [FLOWS.SEARCH]: Search,
    [FLOWS.BUSINESS_SEARCH]: BusinessGigsListing,
    [FLOWS.BUSINESS_CATEGORIES]: BusinessGigsListing,
};

const ListingsPerseus = ({
    displayData,
    breadcrumbs,
    topBarSorting,
    appData = {},
    activeFilters = {},
    appFilters = {},
    knownCrawler,
    listings,
    listingAttributes,
    flowName,
    requestContext,
    tracking,
    errorContext,
    dataLayerObject,
    assumedLanguage,
    isBusiness,
    isBusinessUser,
    userData,
    currency,
    rollouts,
    activeExperiments = {},
    clientExperimentsGroups = {},
    experimentsToAllocate = {},
    shouldShowProjectManagementBanner,
    shouldShowHourlyRateBanners,
    shouldShowExpressDelivery,
    shouldShowProEntryBanner,
    shouldShowPromoteBanner,
    showMediumBucketsModalities,
    inProfessionBasedFeature3Test,
    inProfessionBasedFeature4Test,
    logoMakerConfig,
    relatedSearches,
    subCategoryData,
    vettedGigsCount,
    managedAccount,
    assistantData,
    pushkinConfig,
}) => {
    Object.assign(appData, {
        isBusiness,
        isBusinessUser,
        activeExperiments,
        clientExperimentsGroups,
        relatedSearches,
    });

    useEffect(() => {
        if (!errorContext) {
            trackSearchPerseusPageLoad(dataLayerObject);
            tracker.view();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { userId } = getContext();
        const { query, categoryIds: { subCategoryId } = {} } = requestContext;

        if (userId && listings) {
            if (subCategoryId) {
                trackBrazeEvent({
                    brazeEventName: 'sub_category_visit',
                    brazeEventProperties: { sub_category_id: subCategoryId },
                });
            } else if (query) {
                trackBrazeEvent({
                    brazeEventName: 'search_performed',
                    brazeEventProperties: { term: query },
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    tracker.initialize(tracking);
    void RealtimeListener.init(pushkinConfig);

    if (errorContext) {
        return <ErrorPage currency={currency} />;
    }

    const {
        sorting,
        user: { loggedIn, userCountry },
        flow,
        abTests,
        baseUrl,
    } = appData;
    const Component = ListingsComponentMapper[flow];
    const listingsPerseusClassNames = classNames('listings-perseus');
    const storeProps = {
        knownCrawler,
        listings,
        appData,
        rollouts,
        appFilters,
        activeFilters,
        requestContext,
        displayData,
        userData,
        currency,
        logoMakerConfig,
        subCategoryData,
        experimentsToAllocate,
        assistantData,
        listingAttributes,
        assumedLanguage,
        shouldShowProjectManagementBanner,
        shouldShowHourlyRateBanners,
        shouldShowExpressDelivery,
        shouldShowProEntryBanner,
        shouldShowPromoteBanner,
        showMediumBucketsModalities,
        inProfessionBasedFeature3Test,
        inProfessionBasedFeature4Test,
    };

    return (
        <div className={listingsPerseusClassNames}>
            <Store {...storeProps}>
                <ErrorBoundary fallback={AppError}>
                    <Component
                        loggedIn={loggedIn}
                        userCountry={userCountry}
                        sorting={sorting}
                        breadcrumbs={breadcrumbs}
                        topBarSorting={topBarSorting.map((opt) => ({ ...opt, selected: opt.id === sorting.sortBy }))}
                        abTests={abTests}
                        vettedGigsCount={vettedGigsCount}
                        baseUrl={baseUrl}
                        managedAccount={managedAccount}
                        flow={flow}
                        flowName={flowName}
                        rollouts={rollouts}
                        assistantData={assistantData}
                    />
                </ErrorBoundary>
            </Store>
        </div>
    );
};

ListingsPerseus.propTypes = ListingsPerseusPropTypes;

export default ListingsPerseus;
