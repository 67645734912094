import React from 'react';
import classNames from 'classnames';
import { getContext } from '@fiverr-private/fiverr_context';
import { DEFAULT_FIVERR_LOCALE } from '@fiverr-private/localization';
import { I18n } from '@fiverr-private/i18n-react';

import { sellerNamePrefixStyle } from '../SellerName.ve.css';

export const AdBy = () => {
    const { locale } = getContext();

    // by fragmenting the ad_by text, we try to avoid ad blockers
    if (locale === DEFAULT_FIVERR_LOCALE) {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>A</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>d &nbsp;</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>b</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>y &nbsp;</span>
            </>
        );
    } else if (locale === 'de-DE') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Anz</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>eige von &nbsp;</span>
            </>
        );
    } else if (locale === 'es') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Anu</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>ncio de &nbsp;</span>
            </>
        );
    } else if (locale === 'fr-FR') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Ann</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>once par &nbsp;</span>
            </>
        );
    } else if (locale === 'pt-BR') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Anú</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>ncio por &nbsp;</span>
            </>
        );
    } else if (locale === 'it-IT') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Ann</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>uncio di &nbsp;</span>
            </>
        );
    } else if (locale === 'nl-NL') {
        return (
            <>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>Adver</span>
                <span className={classNames('text-normal', sellerNamePrefixStyle)}>tentie van &nbsp;</span>
            </>
        );
    }

    return (
        <span className={classNames('text-normal', sellerNamePrefixStyle)}>
            <I18n k="gig_cards.badges.promoted_gigs.seller_name_prefix.ad_by" />
            &nbsp;
        </span>
    );
};
