import { Chat, Message } from '@fiverr-private/assistant_conversation';
import { ListingChatContext } from '../../../../../lib/types/ListingChatContext';

export interface ChatData {
    messages: Message[];
    chat: Chat;
}

export interface FetchChatDataProps {
    chatId: string;
    chatContext: ListingChatContext;
}

/**
 * @deprecated
 * This function is no longer used and will be removed in the future. Keeping args to avoid breaking changes.
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const fetchChatDataApi = async (chatId: string, chatContext: ListingChatContext): Promise<ChatData> => ({
    messages: [],
    chat: {} as Chat,
});
