const EXPIRATION_SUFFIX = '_exp';

export const getCookieValue = (name) => {
    if (typeof document === 'undefined') return null;

    const cookies = document.cookie.split('; ');
    const cookie = cookies.find((row) => row.startsWith(`${name}=`));

    return cookie ? cookie.split('=')[1] : null;
};

// Function to set a cookie with expiration
export const setCookieWithExpiration = (name, value, expirationDays) => {
    const date = new Date();
    date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
    const expireDate = date.toUTCString();

    document.cookie = `${name}=${value}; expires=${expireDate}; path=/`;
    document.cookie = `${name}${EXPIRATION_SUFFIX}=${expireDate}; expires=${expireDate}; path=/`;
};

// Function to update only the value without changing expiration
export const updateCookieValue = (name, newValue) => {
    const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
        const [key, value] = cookie.split('=');
        acc[key] = value;
        return acc;
    }, {});

    if (cookies[name]) {
        document.cookie = `${name}=${newValue}; expires=${cookies[name + EXPIRATION_SUFFIX]}; path=/`;
    }
};
