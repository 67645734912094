import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { translate } from '@fiverr-private/i18n-react';
import { getContext } from '@fiverr-private/fiverr_context';
import { Stack } from '@fiverr-private/layout_components';
import { EXPERIMENTS, ROLLOUTS } from '@fiverr-private/listing_experiments';
import { FILTER, FILTERS_LOCATION, isRolesListings, isGigProfessionListings } from '@fiverr-private/listing_lib';
import { FilterProvider } from '../../context/filterContext';
import { buildServiceOptionTitle } from '../../../service/customFilters';
import { filterNavigation, shouldFilterNavigate } from '../../../service/clientNavigation';
import { TOP_GROUPING } from '../../../config/filters';
import { AppContext } from '../../context/listingsContext';
import { NumberOfResults } from '../NumberOfResults/NumberOfResults';
import SortBy from '../sort_by/SortBy';
import HourlyRateEducationTooltip from '../hourlyRateEducationTooltip';
import { FiltersDrawer } from './FiltersDrawer/FiltersDrawer';
import { buildFilterCollections } from './buildFilterCollections';
import { LeafCategoriesFilter } from './LeafCategoriesFilter';
import FilterGroup from './menus/FilterGroup';
import { FILTER_MENU_CONTENT_MAPPING, MENU_CONTENT_TYPES } from './menus/consts';
import { filterAndSort, getFilterDisplay, isActive } from './utils';
import FloatingMenu from './menus/FloatingMenu';
import StickyBar from './sticky_bar';
import ToggleFilters from './menus/content/toggleFilters';

import './menus/content/index.scss';
import './index.scss';

const US_COUNTRY_CODE = 'US';

const moveElementInArray = (arr, from, to, element) => {
    arr.splice(from, 1);
    arr.splice(to, 0, element);
};

export const TopBarFilters = (props) => {
    const { countryCode, abTests } = getContext();
    const { currency, isBusiness, flowName, inOwlsSideFiltersTest } = useContext(AppContext);

    const {
        filters,
        activeFilters,
        selectedFiltersHierarchy,
        subCategory,
        noGigs,
        fromSearchFlow = false,
        isDrawerOpen = false,
        setIsDrawerOpen,
        numOfResult,
        topBarSorting,
        selectedSorting,
        isFixed,
        setIsFixed,
        variant,
        rollouts = {},
    } = props;

    const shouldShowSorterAndNumberOfResults = inOwlsSideFiltersTest;
    const shouldShowDeliveryTime = !inOwlsSideFiltersTest || countryCode === US_COUNTRY_CODE;
    const shouldShowSellerSpeaks = !shouldShowDeliveryTime;

    const { serviceOptionKey, service } = buildServiceOptionTitle(subCategory);
    const additionalProps = { currencySymbol: currency.symbol };

    // TODO: Mert needs to remove those once he handles the new owls' response.
    const serviceFilters = filterAndSort(filters, TOP_GROUPING.SERVICE);
    const sellerFilters = filterAndSort(filters, TOP_GROUPING.SELLER);
    const budgetFilters = filterAndSort(filters, TOP_GROUPING.BUDGET);
    const professionFilters = filterAndSort(filters, TOP_GROUPING.PROFESSION);
    const proficienciesFilters = filterAndSort(filters, TOP_GROUPING.PROFICIENCIES);
    const singleFilters = filterAndSort(filters, TOP_GROUPING.DELIVERY_TIME);
    const sellerSpeaksFilter = sellerFilters.filter((filter) => filter.id === FILTER.SELLER_LANGUAGE.ID);

    const serviceOfferingsIndex = serviceFilters.findIndex((filter) => filter.id === FILTER.SERVICE_OFFERINGS.ID);

    if (serviceOfferingsIndex > 0) {
        const serviceOfferingsFilter = serviceFilters[serviceOfferingsIndex];
        moveElementInArray(serviceFilters, serviceOfferingsIndex, 1, serviceOfferingsFilter);
    }

    const applyFilters = (
        change,
        active,
        filtersGroupName,
        location = FILTERS_LOCATION.FLOATING_TOP,
        source = 'drop_down_filters'
    ) => filterNavigation(change, active, location, source, filtersGroupName);

    const sellerFilterTitle =
        isBusiness || isRolesListings(flowName) || isGigProfessionListings(flowName) ? 'expert' : 'seller';
    const shouldShowSortBy = !isEmpty(topBarSorting);
    const showHourlyRateEducationTooltip =
        rollouts[ROLLOUTS.ZEBRAS_MP_HOURLY_FILTER] && Number(abTests[EXPERIMENTS.ZEBRAS_MP_HOURLY_FILTER]) === 2;

    return (
        <FilterProvider
            activeFilters={activeFilters}
            filters={filters}
            navigationCondition={shouldFilterNavigate}
            applyNavigation={applyFilters}
        >
            <StickyBar isFixed={isFixed} setIsFixed={setIsFixed} variant={variant}>
                <div className="top-filters">
                    <>
                        <FiltersDrawer
                            isOpen={isDrawerOpen}
                            setIsOpen={setIsDrawerOpen}
                            filterCollections={buildFilterCollections(
                                serviceFilters,
                                sellerFilters,
                                budgetFilters,
                                singleFilters,
                                service,
                                isBusiness,
                                subCategory,
                                fromSearchFlow
                            )}
                            selectedFiltersHierarchy={selectedFiltersHierarchy}
                            activeFilters={activeFilters}
                            numOfResult={numOfResult}
                        />
                        {!noGigs && fromSearchFlow && !isEmpty(subCategory) && (
                            <LeafCategoriesFilter data={subCategory} filters={filters} />
                        )}
                        <FilterGroup
                            filters={professionFilters}
                            title={translate('search_perseus.filters.global.profession.alias')}
                            activeFilters={activeFilters}
                            navigationCondition={shouldFilterNavigate}
                            applyNavigation={applyFilters}
                        />
                        <FilterGroup
                            filters={proficienciesFilters}
                            title={translate('search_perseus.filters.global.proficiencies.alias')}
                            activeFilters={activeFilters}
                            navigationCondition={shouldFilterNavigate}
                            applyNavigation={applyFilters}
                        />
                        <FilterGroup
                            filters={serviceFilters}
                            title={translate(serviceOptionKey, { params: { service } })}
                            activeFilters={activeFilters}
                            navigationCondition={shouldFilterNavigate}
                            applyNavigation={applyFilters}
                        />
                        <HourlyRateEducationTooltip delay={2000} show={showHourlyRateEducationTooltip}>
                            <FilterGroup
                                filters={sellerFilters}
                                title={translate(`search_perseus.filter.${sellerFilterTitle}`)}
                                activeFilters={activeFilters}
                                navigationCondition={shouldFilterNavigate}
                                applyNavigation={applyFilters}
                            />
                        </HourlyRateEducationTooltip>

                        {shouldShowSellerSpeaks && (
                            <FilterGroup
                                filters={sellerSpeaksFilter}
                                title={sellerSpeaksFilter[0].alias}
                                activeFilters={activeFilters}
                                navigationCondition={shouldFilterNavigate}
                                applyNavigation={applyFilters}
                            />
                        )}
                        {budgetFilters.length > 0 && (
                            <FloatingMenu
                                title={translate('search_perseus.filters.global.budget.alias')}
                                groupName={TOP_GROUPING.BUDGET}
                                additionalProps={additionalProps}
                                activeFilters={activeFilters}
                                active={isActive(budgetFilters, activeFilters)}
                                contentType={MENU_CONTENT_TYPES.BUDGET}
                                navigationCondition={shouldFilterNavigate}
                                applyNavigation={applyFilters}
                                trackOpen
                            />
                        )}
                        {shouldShowDeliveryTime &&
                            singleFilters.map((filter) => (
                                <FloatingMenu
                                    key={filter.id}
                                    id={filter.id}
                                    data={filter.options}
                                    activeFilters={activeFilters}
                                    groupName={filter.topGrouping}
                                    additionalProps={additionalProps}
                                    {...getFilterDisplay(filter, activeFilters)}
                                    contentType={FILTER_MENU_CONTENT_MAPPING[filter.display_type]}
                                    navigationCondition={shouldFilterNavigate}
                                    applyNavigation={applyFilters}
                                    trackOpen
                                />
                            ))}
                    </>
                </div>
                {!noGigs && <ToggleFilters filters={filters} />}
                {shouldShowSorterAndNumberOfResults && (
                    <Stack gap="3" marginTop="6" alignItems="center">
                        <NumberOfResults resultsCount={numOfResult} />
                        <div className="divider-dot" />
                        {shouldShowSortBy && <SortBy title={selectedSorting.alias} data={topBarSorting} />}
                    </Stack>
                )}
            </StickyBar>
        </FilterProvider>
    );
};

TopBarFilters.propTypes = {
    filters: PropTypes.array.isRequired,
    selectedFiltersHierarchy: PropTypes.array,
    activeFilters: PropTypes.object,
    subCategory: PropTypes.object,
    noGigs: PropTypes.bool,
    fromSearchFlow: PropTypes.bool,
    numOfResult: PropTypes.number,
    topBarSorting: PropTypes.object,
    selectedSorting: PropTypes.object,
    isDrawerOpen: PropTypes.bool,
    setIsDrawerOpen: PropTypes.func,
    isFixed: PropTypes.bool,
    setIsFixed: PropTypes.func,
    variant: PropTypes.string,
    rollouts: PropTypes.object,
};
