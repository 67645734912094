import { useState, useEffect } from 'react';
import { noop } from 'lodash';
import { localStorage } from '@fiverr-private/futile/lib/storage/localStorage';
import { TriggerScenario } from '@fiverr-private/dm_brief_creation';
import { getCookieValue, setCookieWithExpiration, updateCookieValue } from './utils';

export const EXTENSIVE_SCROLLING_COOKIE_KEY = 'bff_pro_listings_extensive_scrolling';
const BFF_FIRST_TIME_LOCAL_STORAGE_KEY = 'bff_pro_listings_first_time';

interface ForceOpenSettings {
    isForceOpen: boolean;
    afterForceOpen: () => void;
    triggerScenario: TriggerScenario | undefined;
}

const initialForceOpenSettings: ForceOpenSettings = {
    isForceOpen: false,
    afterForceOpen: noop,
    triggerScenario: undefined,
};

export const useBffForceOpen = (shouldAllowForcedOpen: boolean, isObservedItemSeen: boolean) => {
    const [forceOpenSettings, setForceOpenSettings] = useState<ForceOpenSettings>(initialForceOpenSettings);

    const resetForceOpenSettings = () => {
        setForceOpenSettings(initialForceOpenSettings);
    };

    useEffect(() => {
        if (shouldAllowForcedOpen) {
            if (isObservedItemSeen) {
                const cookieCurrentValue = getCookieValue(EXTENSIVE_SCROLLING_COOKIE_KEY);

                if (Number(cookieCurrentValue) < 2) {
                    setForceOpenSettings({
                        isForceOpen: true,
                        triggerScenario: TriggerScenario.INTENSIVE_SCROLLING,
                        afterForceOpen: () => {
                            if (cookieCurrentValue === null) {
                                setCookieWithExpiration(EXTENSIVE_SCROLLING_COOKIE_KEY, 1, 14);
                            } else {
                                updateCookieValue(EXTENSIVE_SCROLLING_COOKIE_KEY, 2);
                            }
                        },
                    });
                }
            }

            setTimeout(() => {
                if (!localStorage.get(BFF_FIRST_TIME_LOCAL_STORAGE_KEY)) {
                    setForceOpenSettings({
                        isForceOpen: true,
                        triggerScenario: TriggerScenario.FIRST_TIME,
                        afterForceOpen: () => {
                            localStorage.set(BFF_FIRST_TIME_LOCAL_STORAGE_KEY, true);
                        },
                    });
                }
            }, 5000);
        }
    }, [isObservedItemSeen, shouldAllowForcedOpen]);

    return { forceOpenSettings, resetForceOpenSettings };
};
