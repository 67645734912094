import React from 'react';
import { arrayOf, object, bool } from 'prop-types';
import { I18n } from '@fiverr-private/i18n-react';
import HighlightedRecommendations from '../HighlightedRecommendations';
import { FRIENDLY_SELLER_GIF_PATH, SOURCE_COMPONENT, BASE_KEY } from './constants';
import './styles.scss';

const FtbFriendlyRecommendations = ({ listings, gigs = [], isLoading = false, isNarrowView = false }) => (
    <HighlightedRecommendations
        className="ftb-friendly-recommendations"
        title={<I18n k={`${BASE_KEY}.title`} />}
        description={<I18n k={`${BASE_KEY}.description`} />}
        listings={listings}
        gigs={gigs}
        componentName={SOURCE_COMPONENT}
        iconPath={FRIENDLY_SELLER_GIF_PATH}
        isLoading={isLoading}
        isNarrowView={isNarrowView}
    />
);

FtbFriendlyRecommendations.propTypes = {
    listings: object,
    gigs: arrayOf(object),
    isNarrowView: bool,
};

export default FtbFriendlyRecommendations;
